.App {
  width: 100%;
  height: 100%;
  padding: 32px;
  color: "#ACB9A6";
}

.AppHeader {
  width: 100vw;
  height: 64px;
  
  background-color: #313E26;
  
  color: white;
  padding: 16px;
}